application.register("part_files", class extends Stimulus.Controller {
    get ref() {
        return {
            form: this.targets.find('form')
        }
    }

    sendForm(event) {
        let frm = this.ref.form, formData = new FormData(frm);

        importScript(cdnjs.jquery, () => {
            $.ajax({
                method: frm.getAttribute('method'),
                url: frm.getAttribute('action'),
                data: formData,
                processData: false,
                contentType: false,
                dataType: "json",
            }).done(function (payload) {
                fn_ajaxHandler(payload, function () {
                    if (typeof payload.dialog !== 'undefined' && payload.dialog.length) {
                        LibDialog.show(payload.dialog);
                    }
                });
            }).always(function () {
                frm.querySelector('button[type=submit]').classList.remove('state--loading');
            });
        })
    }
});