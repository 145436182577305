application.register("part_form_registration_event", class extends Stimulus.Controller {
    get ref() {
        return {
            participantsWrapper: this.targets.find('participantsWrapper'),
            participant: this.targets.find('participant'),
            total: this.targets.find('total'),
            rates: this.targets.findAll('rate')
        }
    }

    connect() {
        this.resetCounters()
        this.updatePrice()
    }

    resetCounters() {
        this.totalPrice = 0
        this.rateCounters = []

        this.ref.rates.forEach(rate => {
            this.rateCounters.push(0)
        })
    }

    addParticipant() {
        arguments[0].preventDefault()

        const participantCount = parseInt(this.ref.participantsWrapper.childElementCount)
        const participantHtml = this.ref.participant.innerHTML.replace('<span data-ref="id">1</span>',participantCount+1)

        this.ref.participantsWrapper.insertAdjacentHTML('beforeend',participantHtml)

        locomotive.update()
        loadStimulus([...this.ref.participantsWrapper.querySelectorAll('.elm_form_participant')].pop())
        this.updatePrice()
    }

    updatePrice() {
        this.resetCounters()
        const rateSelects = this.targets.findAll('rateSelect')

        rateSelects.forEach(select => {
            const value = parseInt(select.value)
            this.rateCounters[value]++
        })

        this.rateCounters.forEach((rate, index) => {
            const rateElm = this.ref.rates[index]
            rateElm.textContent = rate
            this.totalPrice += (rateElm.dataset.value * rate)
        })

        this.ref.total.textContent = new Intl.NumberFormat('cs-CZ').format(this.totalPrice)
    }
})
