loadStimulus(document);

window.lui_references_responses = {};

application.register("lib", class extends Stimulus.Controller {
    ripple(e) {
        LibRipple(e.currentTarget, e)
    }

    anchor(e) {
        e.preventDefault();
        LibAnchor.action(e.currentTarget);
    }
});

application.register("lib-dialog", class extends Stimulus.Controller {
    connect() {
        if(typeof this.element.dataset.libDialogOpen !== "undefined") {
            let url = this.element.getAttribute("data-action-url");

            LibDialog.action(this.element, url, ()=>loadStimulus(document.querySelector(".lib--dialog")) );
        }
    }
    show(e) {
        let element = e.currentTarget;

        if (element === window) {
            element = document.querySelector(`[data-action="DOMContentLoaded@window->lib-dialog#show"]`);
        }

        let url = element.getAttribute("data-action-url");

        LibDialog.action(element, url, loadStimulus(document.querySelector(".lib--dialog")));
    }

    hide() {
        LibDialog.hide(loadStimulus(document.querySelector(".lib--dialog")));
    }
});

application.register("lib-tabs", class extends Stimulus.Controller {
    connect() {
        LibTabs(this.element);
    }
});

application.register("lib-tippy", class extends Stimulus.Controller {
    connect() {
        let element = this.element, options = this.data.get("options");
        importScript([cdnjs.popper, cdnjs.tippy],()=> {
            LibTippy(element, options);
        });
    }
});

application.register("lib-slider", class extends Stimulus.Controller {
    connect() {
        LibSlider(this.element, JSON.parse(this.data.get("options")));
    }
});

application.register("lib-masonry", class extends Stimulus.Controller {
    connect() {
        LibMasonry(this.element, JSON.parse(this.data.get("options")));
    }
});

application.register("lib-gallery", class extends Stimulus.Controller {
    connect() {
        LibGallery(this.element, this.data.get("id"));
    }
});

application.register("lib-autosize", class extends Stimulus.Controller {
    connect() {
        importScript(cdnjs.autosize,()=>{
            autosize(this.element);
        });
    }
});

