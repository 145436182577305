function redrawSnippetItem(payload, i, htmlSnippet) {

    var redrawWholeComponent = true;

    if(typeof payload.redrawBlocks !== 'undefined' && typeof payload.redrawBlocks[i] !== 'undefined' && payload.redrawBlocks[i].length) {
        redrawWholeComponent = false;

        for (let iBlock in payload.redrawBlocks[i]) {

            var objectSnippet = $(htmlSnippet);

            var idToFind = payload.redrawBlocks[i][iBlock].block;
            var insertType = payload.redrawBlocks[i][iBlock].insertType;
            var sourceBlock = payload.redrawBlocks[i][iBlock].sourceBlock;

            if(sourceBlock) {
                htmlToReplace = objectSnippet.find("#" + sourceBlock);
                if(htmlToReplace.length) {
                    htmlToReplace.attr('id',idToFind);
                }
            } else if(objectSnippet.is("#" + idToFind)) {
                var htmlToReplace = objectSnippet;
            } else {
                var htmlToReplace = objectSnippet.find("#" + idToFind);
            }

            if(!htmlToReplace.length) {
                continue;
            }

            switch(insertType) {
                case 'default':
                    $("#" + idToFind).replaceWith(htmlToReplace);
                    break;
                case 'append':

                    if(htmlToReplace.length) {
                        htmlToReplace = htmlToReplace.html();
                        $("#" + idToFind).append(htmlToReplace);
                    }
                    break;
            }
            if(typeof locomotive !== "undefined") {
                locomotive.update();
            }
            loadStimulus(document.getElementById(idToFind));
        }
    }

    if (redrawWholeComponent) {
        var snippetItem = $(htmlSnippet);
        if (snippetItem.is('#' + i)) {
            $("#" + i).replaceWith(snippetItem);
        } else {
            var snippet = snippetItem.find("#" + i);
            $("#" + i).replaceWith(snippet);
        }
        if(typeof locomotive !== "undefined") {
            locomotive.update();
        }
        loadStimulus(document.getElementById(i));
    }
}

function fn_ajaxHandler(payload, callback) {
    if (payload) {
        // přesměrování "this" přesměruje na aktuální stránku
        if (payload.redirect) {
            if (payload.redirect === 'this') {
                location.href = window.location.href.split("#")[0];
            } else {
                location.href = payload.redirect;
            }
        }

        // výměna snippetů v šabloně - každý snippet musí mít unikátní ID
        if (payload.snippets) {
            for (let i in payload.snippets) {

                if (typeof payload.snippets[i] === 'object') {
                    for (let iSnippet in payload.snippets[i]) {
                        redrawSnippetItem(payload, i, payload.snippets[i][iSnippet]);
                    }
                } else {
                    redrawSnippetItem(payload, i, payload.snippets[i]);
                }
            }
        }

        // výměna adresy v adresním řádku
        if (payload.replaceState) {
            window.history.replaceState(null, payload.replaceState.title || null, payload.replaceState.url);

            if (payload.replaceState.title) {
                document.title = payload.replaceState.title;
            }

            if (typeof ga === "function") {
                ga('send', 'pageview', window.location.pathname + window.location.search);
            }

            if (typeof gtag === 'function') {
                gtag('event', 'page_view', {'page_path': window.location.pathname + window.location.search});
            }
        }

        if (callback) {
            callback();
        }
    }
}