application.register("layout_header", class extends Stimulus.Controller {
    connect() {
        let targetPosition = 5;
        let lastScrollTop = 0;
        let scrollTimout;
        let header = this.element;

        function setHeaderState(position) {
            if (position > targetPosition) {
                header.classList.add("is--header-not-top");
            } else {
                header.classList.remove("is--header-not-top", "is--header-pinned", "is--header-unpinned");
            }

            // clearTimeout(scrollTimout);
            // scrollTimout = setTimeout(()=>{
            //     if (position > lastScrollTop){
            //         header.classList.remove("is--header-pinned");
            //         header.classList.add("is--header-unpinned");
            //     } else {
            //         header.classList.remove("is--header-unpinned");
            //         header.classList.add("is--header-pinned");
            //     }
            //
            //     lastScrollTop = position <= 0 ? 0 : position;
            // },5);
        }
        bodyLoaded(function() {
            setTimeout(()=>{
                if(typeof locomotive !== "undefined" && !locomotive.isMobile) {
                    locomotive.on("scroll",(e)=>{
                        let position = e.delta.y;
                        setHeaderState(position);
                    });
                }
                else {
                    if(!document.documentElement.classList.contains("ie")) {
                        window.addEventListener("scroll",()=> {
                            let position = document.documentElement.scrollTop;
                            setHeaderState(position);
                        });
                    }
                    else {
                        window.addEventListener("scroll",debounce(()=> {
                            let position = document.documentElement.scrollTop;
                            setHeaderState(position);
                        },100));
                    }
                }
            },1000);
        });
    }

    showSearch(e) {
        e.preventDefault();

        this.targets.find('search').classList.add("state--active");
        this.targets.find('search').querySelector('input').focus();
    }

    hideSearch(e) {
        e.preventDefault();
        e.currentTarget.blur();

        this.targets.find('search').classList.remove("state--active");
    }
});
